/*
xs, extra-small: 0px or larger
sm, small: 600px or larger
md, medium: 960px or larger
lg, large: 1280px or larger
xl, extra-large: 1920px or larger
*/
html {
  font-size: 16px;
}
@media (max-width: 960px) {
  html {
    font-size: 14px;
  }
}
body {
  /* Safari */
  font-family: 'Roboto', sans-serif;
  background-color: #e5e5e5;
  color: #777777;
  user-select: none;
}
body .loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body .jh-subheader__title {
  font-size: 1.25rem;
  color: #333333;
  font-weight: 400;
  line-height: normal;
}
body .profile-icon {
  margin-right: -15px;
}
body img {
  pointer-events: none;
}
body input[type=number]::-webkit-inner-spin-button,
body input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body input[type=number] {
  -moz-appearance: textfield;
}
body .SnackBar-* {
  display: flex;
  flex-flow: initial;
}
@media only screen and (max-width: 600px) {
  body div[role="alert"] {
    display: flex;
    flex-flow: initial;
  }
}
body .login-page {
  height: 100vh;
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
}
body .login-page .login-page-wrapper {
  display: flex;
  align-items: center;
  height: inherit;
  justify-content: center;
}
body .login-page .login-page-wrapper .login-form {
  padding: 2rem;
  background-color: #fff;
  border-radius: 2.5rem;
}
body .login-page .login-page-wrapper .login-form button {
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
body .login-page .login-page-wrapper .login-form .MuiAutocomplete-endAdornment {
  top: calc(50% - 20px);
}
body .login-page.doctor-login-page {
  height: calc(100vh - 64px);
}
body .login-page .office365-button {
  background: url('https://download.logo.wine/logo/Office_365/Office_365-Logo.wine.png') 10px 10px no-repeat;
  background-size: 150px auto;
  background-position: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 15px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body .login-page .office365-button .MuiButton-label {
  opacity: 0;
}
body .forgot-password-link-wrapper {
  display: flex;
  justify-content: flex-end;
}
body .forgot-password-link-wrapper .forgot-password-link {
  text-decoration: none;
  cursor: pointer;
}
body .forgot-password-link-wrapper .forgot-password-link:hover {
  color: #006a98;
}
body .page-back-row .page-back--link {
  display: flex;
  align-items: center;
}
body .page-back-row .page-back--link svg {
  margin-right: 1rem;
  cursor: pointer;
}
body .leftmenu-icons,
body .header {
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
}
body .leftmenu-icons.menuActive,
body .header.menuActive {
  color: #0098da;
  background-color: #cce0f8;
}
body .leftmenu-icons.menuActive:hover,
body .header.menuActive:hover {
  color: #0098da;
  background-color: #cce0f8;
}
body .leftmenu-icons.menuActive svg,
body .header.menuActive svg {
  color: #0098da;
}
body .leftmenu-icons.menuActive .fas,
body .header.menuActive .fas {
  color: #0098da;
}
body .leftmenu-icons .fas,
body .header .fas {
  font-size: 20px;
  margin-left: 3px;
}
body .leftmenu-icons .header-wrapper,
body .header .header-wrapper {
  display: flex;
  align-items: center;
  line-height: normal;
  justify-content: space-between;
}
body .leftmenu-icons .header-wrapper img,
body .header .header-wrapper img {
  margin-right: 0.5rem;
  height: 1.5rem;
}
body .leftmenu-icons .header-wrapper .logo-text,
body .header .header-wrapper .logo-text {
  margin-top: 0.5rem;
  color: #273785;
  text-transform: uppercase;
}
body .jh-aside-left__expand ul {
  padding-top: 0;
}
body .page-header {
  font-size: 1.25rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #333333;
}
body .CustLodder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}
body .CustLodder > div svg {
  width: 100% !important;
  height: 100% !important;
}
@supports (-webkit-hyphens:none) {
  body .CustLodder {
    top: 0;
  }
}
body .circular-loader {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 40px;
  width: 40px;
  position: absolute;
}
body footer {
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #0098da;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  z-index: 1111;
  font-size: 0.925rem;
}
body footer a {
  margin-left: 10px;
  text-decoration: none;
  color: #41c5ff;
}
body .main-container {
  padding: 1.5rem;
  max-width: 100%;
  margin-bottom: 1.5rem;
}
body .main-container .search-requests-wrapper {
  padding: 1rem;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container {
  padding: 0.35rem 1rem;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-refresh-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-filter-wrapper .category-header-wrapper {
  padding: 0;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-filter-wrapper .category-header-wrapper .category-filter {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-filter-wrapper .category-header-wrapper .category-filter .category-paper {
  position: absolute;
  top: 1.75rem;
  right: 0;
  z-index: 1;
  padding: 1.5rem 1rem 0.9375rem;
  border: 1px solid #ccc;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-filter-wrapper .category-header-wrapper .category-filter .category-paper .filter-container {
  min-width: 280px;
  width: 100%;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-filter-wrapper .category-header-wrapper .category-filter .category-paper .filter-container .filter-action-btns {
  display: flex;
  justify-content: flex-end;
}
body .main-container .admission-manager-dashboard-wrapper .admission-dashboard-container .admission-filter-wrapper .category-header-wrapper .category-filter .category-paper .filter-container .filter-action-btns button {
  margin-left: 10px;
}
body .all-resouces-list-wrapper .show-req-btn {
  min-height: 40px;
  margin-right: 10px;
}
body .all-resouces-list-wrapper .show-req-btn .MuiButton-label {
  font-weight: bold;
}
body .all-resouces-list-wrapper .show-add-patient-btn {
  min-height: 36px;
}
body .respurce-list-paper {
  padding: 1rem;
  box-shadow: 0px 1px 8px #cecece99;
  border-radius: 4px;
}
body .respurce-list-paper .build-query-icon {
  display: flex;
  justify-content: end;
  cursor: pointer;
  align-items: flex-end;
}
body .autocomplete-field label {
  font-family: 'Roboto', sans-serif;
  transform: translate(14px, 12px) scale(1);
  color: #777;
}
body .request-container {
  margin-bottom: 2rem;
}
body .request-container .category-header-wrapper {
  padding: 0 1rem;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .request-container .category-header-wrapper .category-information {
  display: flex;
  margin: 0.5rem 0;
}
body .request-container .category-header-wrapper .category-information .category-label {
  margin-right: 5px;
  font-size: 1.25rem;
  color: #333;
  font-weight: 400;
}
body .request-container .category-header-wrapper .category-information .category-name {
  font-size: 1.3125rem;
  font-weight: 400;
}
body .request-container .category-header-wrapper .category-filter {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
}
body .request-container .category-header-wrapper .category-filter .category-paper {
  position: absolute;
  top: 1.75rem;
  right: 0;
  z-index: 1;
  padding: 1.5rem 1rem 0.9375rem;
  border: 1px solid #ccc;
}
body .request-container .category-header-wrapper .category-filter .category-paper .filter-container {
  min-width: 280px;
  width: 100%;
}
body .request-container .category-header-wrapper .category-filter .category-paper .filter-container .filter-action-btns {
  display: flex;
  justify-content: flex-end;
}
body .request-container .category-header-wrapper .category-filter .category-paper .filter-container .filter-action-btns button {
  margin-left: 10px;
}
body .request-container .request-table-list th,
body .request-container .request-table-list td {
  padding: 0.5rem 1rem;
}
body .request-container .action-btn-wrapper {
  display: flex;
}
body .request-container .action-btn-wrapper button {
  padding: 5px;
  min-width: 36px;
  margin-right: 8px;
}
body .request-container .action-btn-wrapper button:last-child {
  margin-right: 0;
}
body .request-container .action-btn-wrapper button svg {
  width: 1.25rem;
  height: 1.25rem;
}
body .request-container .common-action-paper-wrapepr {
  height: 100%;
  box-shadow: 0px 1px 8px #cecece99;
  border-radius: 4px;
}
body .request-container .common-action-paper-wrapepr .common-action-btn-wrapper .action-column-title {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .request-container .common-action-paper-wrapepr .common-action-btn-wrapper .button-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}
@media (max-width: 960px) {
  body .request-container .common-action-paper-wrapepr .common-action-btn-wrapper .button-wrapper {
    max-height: calc(100vh - 300px);
  }
}
body .request-container .common-action-paper-wrapepr .common-action-btn-wrapper .button-wrapper button {
  margin: 0.5rem 0;
  padding: 5px;
  min-width: 36px;
}
body .request-container .common-action-paper-wrapepr .common-action-btn-wrapper .button-wrapper button:last-child {
  margin-bottom: 1.5rem;
}
body .request-container .common-action-paper-wrapepr .common-action-btn-wrapper .button-wrapper button svg {
  width: 1.25rem;
  height: 1.25rem;
}
body .request-container .request-table-row .request-table-column {
  word-break: break-all;
  color: rgba(0, 0, 0, 0.67);
  max-width: 150px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
body .request-container .request-table-row .request-table-column.assigned-to {
  max-width: 175px;
  min-width: 100px;
}
body .request-container .request-table-row .request-table-column.request-date {
  min-width: 120px;
}
body .request-container .request-table-row .request-table-column.request-status-name .status-indication-green {
  background-color: #C9EBCD;
  color: #40A52F;
  min-width: 100px;
  min-height: 28px;
}
body .request-container .request-table-row .request-table-column.request-status-name .status-indication-orange {
  background-color: #FEF0D6;
  color: #FDA948;
  min-width: 100px;
  min-height: 28px;
}
body .request-container .request-table-row .request-table-column.request-status-name .status-indication-red {
  background-color: #ffc8c8;
  color: #ED5954;
  min-width: 100px;
  min-height: 28px;
}
body .request-container .request-table-row .request-table-column.request-status-name .status-indication-default {
  background-color: #D2DEE6;
  color: #5C7A8D;
  min-width: 100px;
  min-height: 28px;
}
body .request-container .request-table-row .request-table-column.request-status-name > span {
  padding: 1rem;
}
body .request-container .request-table-row:hover {
  background-color: rgba(235, 237, 239, 0.5);
  cursor: pointer;
}
body .request-container .category-paper-wrapper {
  box-shadow: 0px 1px 8px #cecece99;
  border-radius: 4px;
}
body .request-container .category-paper-wrapper .request-table-list-header {
  background-color: rgba(235, 237, 239, 0.87);
}
body .request-container .category-paper-wrapper .request-table-list-header th {
  color: #575962;
  border: none;
  text-transform: uppercase;
}
body .request-container.admission-request-container {
  margin-top: -12px;
}
body .profile-setting-wrapper .profile-setting-title {
  padding-bottom: 1rem;
  color: #000;
}
body .profile-setting-wrapper .profile-info-row {
  display: flex;
  align-items: center;
}
body .profile-setting-wrapper .profile-info-row svg {
  margin-right: 1rem;
  color: #808080;
}
body .profile-setting-wrapper .profile-info-row h6 {
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
body .profile-setting-wrapper .profile-info-row:first-child {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
}
body .profile-setting-wrapper .category-paper {
  min-height: 300px;
  height: auto;
}
body .profile-setting-wrapper .change-password-btn {
  display: flex;
  justify-content: flex-end;
}
body .add-user-wrapper {
  padding: 1.5rem;
  margin-bottom: 50px;
}
body .add-user-wrapper .add__user-header {
  border-radius: 4px 4px 0 0;
  background: #fff;
  box-shadow: 0px 1px 8px #cecece99;
}
body .add-user-wrapper .add__user-header .landing-tabs .tab {
  font-size: 1rem;
  padding: 0.375rem 1.5rem;
  font-weight: 400;
}
body .add-user-wrapper .add-user-btn-wrapper {
  display: flex;
  justify-content: flex-end;
}
body .profile-setting-wrapper .profile-setting-title {
  padding-bottom: 1rem;
  color: #000;
}
body .profile-setting-wrapper .profile-info-row {
  display: flex;
  align-items: center;
}
body .profile-setting-wrapper .profile-info-row svg {
  margin-right: 1rem;
  color: #808080;
}
body .profile-setting-wrapper .profile-info-row h6 {
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
body .profile-setting-wrapper .profile-info-row:first-child {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
}
body .profile-setting-wrapper .category-paper {
  min-height: 300px;
  height: auto;
}
body .profile-setting-wrapper .change-password-btn {
  display: flex;
  justify-content: flex-end;
}
body .category-paper {
  padding: 1rem;
  box-shadow: 0px 1px 8px #cecece99;
  border-radius: 4px;
}
body .execute-query-button-wrapper {
  display: flex;
  justify-content: end;
}
body .execute-query-button-wrapper .clear-button {
  margin-right: 1rem;
}
body .execute-query-button-wrapper .endpoint-url {
  display: flex;
  align-items: center;
}
body .execute-query-button-wrapper .common-error-message {
  display: flex;
  align-items: center;
}
body .end-point-wrapper {
  display: flex;
  align-items: center;
}
body .end-point-wrapper .label {
  margin-right: 1rem;
}
body .end-point-wrapper-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .disabled-fields {
  display: none;
}
body .jupiter-dialog-model div[role="dialog"] {
  position: relative;
  overflow: hidden;
  max-height: 70%;
}
body .jupiter-dialog-model div[role="dialog"] #alert-dialog-title {
  margin-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;
  padding: 0.5rem 1rem;
}
body .jupiter-dialog-model div[role="dialog"] #alert-dialog-title h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body .jupiter-dialog-model div[role="dialog"] #alert-dialog-title h2 .close-icon svg {
  color: #333333;
}
body .jupiter-dialog-model div[role="dialog"] .alert-dialog-content_wrapper {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
body .jupiter-dialog-model div[role="dialog"] .alert-dialog-content_wrapper p {
  margin: 0;
}
body .jupiter-dialog-model div[role="dialog"] .alert-dialog-footer_wrapper {
  padding: 1rem;
  border-top: 1px solid #e5e5e5;
}
body .jupiter-dialog-model .payment-details-wrapper .payment-field {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
body .jupiter-dialog-model .payment-details-wrapper .payment-field:first-child {
  padding-top: 0;
}
body .jupiter-dialog-model .payment-details-wrapper .payment-field .payment-confirmation-text {
  width: 100%;
  font-weight: 400;
  color: #777;
  font-size: 1rem;
  letter-spacing: 0.5px;
}
body .jupiter-dialog-model .payment-details-wrapper .payment-field .payment-label {
  max-width: 150px;
  width: 100%;
  font-weight: 400;
  color: #777;
  font-size: 1rem;
  letter-spacing: 0.5px;
}
body .jupiter-dialog-model .payment-details-wrapper .payment-field .payment-value {
  max-width: calc(100% - 155px);
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
body .jupiter-dialog-model .request-field {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}
body .jupiter-dialog-model .request-field:first-child {
  padding-top: 0;
}
body .jupiter-dialog-model .request-field .category-label {
  max-width: 300px;
  width: 100%;
  font-weight: 400;
  color: #777;
  font-size: 1rem;
}
body .jupiter-dialog-model .request-field .category-value {
  max-width: calc(100% - 155px);
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
}
body .jupiter-dialog-model .request-field .org-name {
  text-transform: capitalize;
}
body .jupiter-dialog-model .request-field .file-section .file-row .download-btn {
  margin-top: 10px;
}
body .jupiter-dialog-model .request-field .file-section .file-row .download-btn > span {
  line-height: 1.5;
}
body .jupiter-dialog-model .request-field .file-section .file-row .download-btn > span .icon-download {
  margin-right: 8px;
}
body .jupiter-dialog-model .request-field .file-section .file-name {
  border-bottom: 1px solid #eee;
}
body .landing-page-button-wrapper {
  justify-content: end;
  padding: 1rem;
}
body .landing-page-button-wrapper .landing-page-button {
  border-radius: 15px;
}
body .landing-page-image-wrapper {
  border-bottom: 1px solid #ededed;
}
body .landing-page-image-wrapper .landing-page-card-img {
  object-fit: none;
  height: 100px;
}
body .landing-page-app-name-wrapper {
  display: flex;
  justify-content: center;
}
body .landing-page-main-card {
  border-radius: 30px;
}
.page-error_wrapper {
  margin: 2rem auto 0;
}
.page-error_wrapper .page-error_grid .page-error_content .page-error_heading {
  font-size: 2.8rem;
  color: #41c5ff;
  margin: 0 0 0.5rem;
}
.page-error_wrapper .page-error_grid .page-error-grid__img {
  margin: 2rem 0 0;
}
/*# sourceMappingURL=style.css.map */
