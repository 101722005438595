// main: ../../main.less

html {
    font-size: 16px;

    @media (max-width: 960px) {
        font-size: 14px;
    }
}

body {

    .loader {
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #3498db;
        width: 20px;
        height: 20px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
      }
      
      /* Safari */
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

    font-family: @main-font;
    background-color: @page-bg-color;
    color: @gray-light;
    user-select: none;

    .jh-subheader__title {
        font-size: 1.25rem;
        // color: rgba(0, 0, 0, 0.87);
        color: #333333;
        font-weight: 400;
        line-height: normal;
    }

    .profile-icon {
        margin-right: -15px;
    }

    img {
        pointer-events: none;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .SnackBar-* {
        display: flex;
        flex-flow: initial;
    }

    @media only screen and (max-width: 600px) {
        div[role="alert"] {
            display: flex;
            flex-flow: initial;
        }
    }

    // Login Page 
    .login-page {
        height: 100vh;
        max-width: 90%;
        width: 100%;
        margin: 0 auto;

        .login-page-wrapper {
            display: flex;
            align-items: center;
            height: inherit;
            justify-content: center;

            // .login-img {
            //     img {
            //         max-width: 100%;
            //         width: 100%;
            //     }
            // }
            .login-form {
                padding: 2rem;
                background-color: #fff;
                border-radius: 2.5rem;

                button {
                    width: 100%;
                    padding: 8px;
                    border-radius: 3px;
                    -moz-background-clip: padding;
                    -webkit-background-clip: padding-box;
                    background-clip: padding-box;
                }

                .MuiAutocomplete-endAdornment {
                    top: calc(50% - 20px);
                }
            }
        }

        &.doctor-login-page {
            height: calc(100vh - 64px);
        }

        .office365-button {
            background: url('https://download.logo.wine/logo/Office_365/Office_365-Logo.wine.png') 10px 10px no-repeat;
            background-size: 150px auto;
            background-position: center;
            box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 15px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);

            .MuiButton-label {
                opacity: 0;
            }
        }
    }

    //Forgot Password
    .forgot-password-link-wrapper {
        display: flex;
        justify-content: flex-end;

        .forgot-password-link {
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: rgb(0, 106, 152);
            }
        }
    }

    .page-back-row {
        .page-back--link {
            display: flex;
            align-items: center;

            svg {
                margin-right: 1rem;
                cursor: pointer;
            }
        }
    }

    .leftmenu-icons,
    .header {
        padding-left: 20px;
        padding-right: 20px;
        height: 50px;

        &.menuActive {
            color: @brand-primary;
            background-color: #cce0f8;

            &:hover {
                color: @brand-primary;
                background-color: #cce0f8;
            }

            svg {
                color: @brand-primary;
            }

            .fas {
                color: @brand-primary;
            }
        }

        .fas {
            font-size: 20px;
            margin-left: 3px;
        }

        .header-wrapper {
            display: flex;
            align-items: center;
            line-height: normal;
            justify-content: space-between;

            img {
                margin-right: 0.5rem;
                height: 1.5rem;
            }

            .logo-text {
                margin-top: 0.5rem;
                color: #273785;
                text-transform: uppercase;
            }
        }
    }

    .jh-aside-left__expand {
        ul {
            padding-top: 0;
        }
    }

    .page-header {
        font-size: 1.25rem;
        font-family: @main-font;
        font-weight: 500;
        color: @gray-dark;
    }

    .CustLodder {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;

        >div {
            svg {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    @supports (-webkit-hyphens:none) {
        .CustLodder {
            top: 0;
        }
    }

    // circular loader
    .circular-loader {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 40px;
        width: 40px;
        position: absolute;
    }

    // Fotter
    footer {
        //box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
        padding: 10px;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: @brand-primary;
        color: @accent-white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        box-sizing: border-box;
        z-index: 1111;
        font-size: 0.925rem;

        a {
            margin-left: 10px;
            text-decoration: none;
            color: lighten(@brand-primary, 20%);
        }
    }

    .main-container {
        padding: 1.5rem;
        max-width: 100%;
        margin-bottom: 1.5rem;

        .search-requests-wrapper {
            padding: 1rem;
        }

        .admission-manager-dashboard-wrapper {
            .admission-dashboard-container {
                padding: 0.35rem 1rem;

                .admission-refresh-btn-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .admission-filter-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;                    

                    .category-header-wrapper {                        

                        padding: 0;
                        min-height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .category-filter {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            position: relative;
                            color: rgba(0, 0, 0, 0.54);

                            .category-paper {
                                position: absolute;
                                top: 1.75rem;
                                right: 0;
                                z-index: 1;
                                padding: 1.5rem 1rem 0.9375rem;
                                border: 1px solid #ccc;

                                .filter-container {
                                    min-width: 280px;
                                    width: 100%;

                                    .filter-action-btns {
                                        display: flex;
                                        justify-content: flex-end;

                                        button {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .all-resouces-list-wrapper {

        // padding-top: 5px;
        .show-req-btn {
            min-height: 40px;
            margin-right: 10px;

            .MuiButton-label {
                font-weight: bold;
            }
        }

        .show-add-patient-btn {
            min-height: 36px;
        }
    }

    .respurce-list-paper {
        padding: 1rem;
        box-shadow: 0px 1px 8px #cecece99;
        border-radius: 4px;

        .build-query-icon {
            display: flex;
            justify-content: end;
            cursor: pointer;
            align-items: flex-end;
        }
    }

    .autocomplete-field label {
        // font-size: 0.875rem;
        font-family: 'Roboto', sans-serif;
        transform: translate(14px, 12px) scale(1);
        color: #777;
    }

    .autocomplete-field {
        >div {

            // > div {
            //     padding: 0 9px !important;
            //     min-height: 39px;
            //     input{
            //         padding: 0 4px !important;
            //         font-size: 0.875rem !important;
            //     }
            // }

            // > div:nth-child(2){
            //     > div {
            //         height: 26px;
            //         margin: 5px 3px;
            //         > span {
            //             font-size: 12px;
            //             line-height: normal;
            //         }
            //     }
            // }
        }
    }

    .request-container {
        // margin-top:8px;
        margin-bottom: 2rem;

        .category-header-wrapper {
            padding: 0 1rem;
            // border-bottom: 1px solid #e0e0e0;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .category-information {
                display: flex;
                margin: 0.5rem 0;

                .category-label {
                    margin-right: 5px;
                    font-size: 1.25rem;
                    color: #333;
                    font-weight: 400;
                }

                .category-name {
                    font-size: 1.3125rem;
                    font-weight: 400;
                }
            }

            .category-filter {
                cursor: pointer;
                display: flex;
                align-items: center;
                position: relative;
                color: rgba(0, 0, 0, 0.54);

                .category-paper {
                    position: absolute;
                    top: 1.75rem;
                    right: 0;
                    z-index: 1;
                    padding: 1.5rem 1rem 0.9375rem;
                    border: 1px solid #ccc;

                    .filter-container {
                        min-width: 280px;
                        width: 100%;

                        .filter-action-btns {
                            display: flex;
                            justify-content: flex-end;

                            button {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }

        // .request-table-list th:first-child, .request-table-list td:first-child {
        //     //padding-left: 24px;
        // }
        // .request-table-list th:last-child, .request-table-list td:last-child{
        //     //padding-right: 24px;
        //     min-width: 260px;
        // }

        // .request-table-list th:last-child, .request-table-list td:last-child {
        //     width:160px;
        // }

        .request-table-list th,
        .request-table-list td {
            padding: 0.5rem 1rem;
        }

        .action-btn-wrapper {
            display: flex;

            button {
                padding: 5px;
                min-width: 36px;
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }

                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }

        .common-action-paper-wrapepr {
            height: 100%;
            box-shadow: 0px 1px 8px #cecece99;
            border-radius: 4px;

            .common-action-btn-wrapper {

                // padding: 10px;
                // justify-content: center;
                // display: flex;
                // align-items: center;
                // flex-direction: column;
                // height: 100%;
                .action-column-title {
                    min-height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .button-wrapper {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    // max-height: calc(100vh - 275px);
                    max-height: 400px;
                    overflow-y: auto;

                    @media (max-width:960px) {
                        max-height: calc(100vh - 300px);
                    }

                    button {
                        margin: 0.5rem 0;
                        padding: 5px;
                        min-width: 36px;

                        &:last-child {
                            margin-bottom: 1.5rem;
                        }

                        svg {
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                    }
                }

            }
        }

        .request-table-row {
            .request-table-column {
                word-break: break-all;
                color: rgba(0, 0, 0, 0.67);
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // overflow: hidden;
                max-width: 150px;

                -webkit-user-select: text;
                -moz-user-select: text;
                -ms-user-select: text;
                user-select: text;

                &.assigned-to {
                    max-width: 175px;
                    min-width: 100px;
                }

                &.request-date {
                    min-width: 120px;
                }

                &.request-status-name {
                    .status-indication-green {
                        background-color: @completed-bg;
                        color: @completed-text;
                        // border:solid 1px @completed-text;
                        min-width: 100px;
                        min-height: 28px;
                    }

                    .status-indication-orange {
                        background-color: @inprogress-bg;
                        color: @inprogress-text;
                        // border:solid 1px @inprogress-text;
                        min-width: 100px;
                        min-height: 28px;
                    }

                    .status-indication-red {
                        background-color: @expired-bg;
                        color: @expired-text;
                        // border: solid 1px @expired-text;
                        min-width: 100px;
                        min-height: 28px;
                    }

                    .status-indication-default {
                        background-color: @not-started-bg;
                        color: @not-started-text;
                        // border: solid 1px @not-started-text;
                        min-width: 100px;
                        min-height: 28px;
                    }

                    >span {
                        padding: 1rem;
                    }
                }
            }

            &:hover {
                background-color: rgba(235, 237, 239, .5);
                cursor: pointer;
            }
        }

        .category-paper-wrapper {
            box-shadow: 0px 1px 8px #cecece99;
            border-radius: 4px;

            .request-table-list-header {
                background-color: rgba(235, 237, 239, .87);

                th {
                    color: #575962;
                    border: none;
                    text-transform: uppercase;
                }
            }
        }

        &.admission-request-container {
            margin-top: -12px;
        }

    }

    //Profile setting page
    .profile-setting-wrapper {
        .profile-setting-title {
            padding-bottom: 1rem;
            color: #000;
        }

        .profile-info-row {
            display: flex;
            align-items: center;

            svg {
                margin-right: 1rem;
                color: #808080;
            }

            h6 {
                font-size: 18px;
                letter-spacing: 0.5px;
                font-weight: 500;
            }

            &:first-child {
                border-bottom: 1px solid #ddd;
                margin-bottom: 1rem;
            }
        }

        .category-paper {
            min-height: 300px;
            height: auto;
        }

        .change-password-btn {
            display: flex;
            justify-content: flex-end;
        }
    }

    //Add User Page
    .add-user-wrapper {
        padding: 1.5rem;
        margin-bottom: 50px;

        .add__user-header {
            border-radius: 4px 4px 0 0;
            background: #fff;
            box-shadow: 0px 1px 8px #cecece99;

            .landing-tabs {
                .tab {
                    font-size: 1rem;
                    padding: 0.375rem 1.5rem;
                    font-weight: 400;
                }
            }
        }

        .add-user-btn-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }

    //Profile setting page
    .profile-setting-wrapper {
        .profile-setting-title {
            padding-bottom: 1rem;
            color: #000;
        }

        .profile-info-row {
            display: flex;
            align-items: center;

            svg {
                margin-right: 1rem;
                color: #808080;
            }

            h6 {
                font-size: 18px;
                letter-spacing: 0.5px;
                font-weight: 500;
            }

            &:first-child {
                border-bottom: 1px solid #ddd;
                margin-bottom: 1rem;
            }
        }

        .category-paper {
            min-height: 300px;
            height: auto;
        }

        .change-password-btn {
            display: flex;
            justify-content: flex-end;
        }
    }

    .category-paper {
        padding: 1rem;
        box-shadow: 0px 1px 8px #cecece99;
        border-radius: 4px;
    }

    .execute-query-button-wrapper {
        display: flex;
        justify-content: end;

        .clear-button {
            margin-right: 1rem;
        }

        .endpoint-url {
            display: flex;
            align-items: center;
        }

        .common-error-message {
            display: flex;
            align-items: center;
        }
    }

    .end-point-wrapper {
        display: flex;
        align-items: center;

        .label {
            margin-right: 1rem;
        }
    }

    .end-point-wrapper-loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .disabled-fields {
        display: none;
    }

    .jupiter-dialog-model {
        div[role="dialog"] {
            position: relative;
            overflow: hidden;
            max-height: 70%;

            #alert-dialog-title {
                margin-bottom: 1rem;
                border-bottom: 1px solid #e5e5e5;
                padding: 0.5rem 1rem;

                h2 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .close-icon {
                        svg {
                            color: #333333;
                        }
                    }
                }
            }

            .alert-dialog-content_wrapper {
                padding: 0.5rem 1rem;
                margin-bottom: 1rem;

                p {
                    margin: 0;
                }
            }

            .alert-dialog-footer_wrapper {
                padding: 1rem;
                border-top: 1px solid #e5e5e5;
            }
        }

        .payment-details-wrapper {
            .payment-field {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #eee;
                padding: 10px 0;

                &:first-child {
                    padding-top: 0;
                }

                .payment-confirmation-text {
                    width: 100%;
                    font-weight: 400;
                    color: #777;
                    font-size: 1rem;
                    letter-spacing: 0.5px;
                }

                .payment-label {
                    max-width: 150px;
                    width: 100%;
                    font-weight: 400;
                    color: #777;
                    font-size: 1rem;
                    letter-spacing: 0.5px;
                }

                .payment-value {
                    max-width: calc(100% - 155px);
                    width: 100%;
                    font-size: 1rem;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                }
            }
        }

        // Request View Details popup
        .request-field {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;

            &:first-child {
                padding-top: 0;
            }

            .category-label {
                max-width: 300px;
                width: 100%;
                font-weight: 400;
                color: #777;
                font-size: 1rem;
                // letter-spacing: 0.5px;
            }

            .category-value {
                max-width: calc(100% - 155px);
                width: 100%;
                font-size: 1rem;
                font-weight: 500;
                // letter-spacing: 0.5px;
            }

            .org-name {
                text-transform: capitalize;
            }

            .file-section {
                .file-row {
                    .download-btn {
                        margin-top: 10px;

                        >span {
                            line-height: 1.5;

                            .icon-download {
                                margin-right: 8px;
                            }
                        }
                    }
                }

                .file-name {
                    border-bottom: 1px solid #eee;
                }
            }

            // &:last-child{
            //     border-bottom: none;
            // }
        }

    }

    .landing-page-button-wrapper {
        justify-content: end;
        padding: 1rem;

        .landing-page-button {
            border-radius: 15px;
        }
    }

    .landing-page-image-wrapper {
        border-bottom: 1px solid #ededed;

        .landing-page-card-img {
            object-fit: none;
            height: 100px;
        }
    }

    .landing-page-app-name-wrapper {
        display: flex;
        justify-content: center;
    }

    .landing-page-main-card {
        border-radius: 30px;
    }
}