// main: ../main.less
.page-error_wrapper{
    margin: 2rem auto 0;
    .page-error_grid{
        .page-error_content{
            .page-error_heading{
                font-size: 2.8rem;
                color: lighten(@brand-primary, 20%);
                margin: 0 0 .5rem;
            }
            // .page-error_message{
            //     color: @brand-primary;
            // }
        }
        .page-error-grid{
            &__img{
                margin: 2rem 0 0;
            }
        }
    }
}